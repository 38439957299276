import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';

import { useEventListener } from '../../../../hooks';
import { Icon } from '../../../icons';
import { MenuItemWithDropdownProps } from '../../types';

export const HorizontalMenuItemWithDropdown = ({ text, menu, onClick }: MenuItemWithDropdownProps) => {
  const listElementRef = useRef<HTMLLIElement>(null);
  const [show, setShow] = useState(false);

  const mouseDownEvent = useCallback((event: MouseEvent) => {
    const closestLi = (event.target as HTMLElement).closest('li');
    if (closestLi?.classList.contains('nav-menu-dropdown-item')) {
      listElementRef.current?.removeAttribute('data-hover');
    }
  }, []);

  const mouseEnterEvent = useCallback(() => {
    listElementRef.current?.setAttribute('data-hover', '');
  }, []);

  const mouseLeaveEvent = useCallback(() => {
    listElementRef.current?.removeAttribute('data-hover');
  }, []);

  useEventListener<MouseEvent>('mousedown', mouseDownEvent, listElementRef);
  useEventListener<MouseEvent>('mouseenter', mouseEnterEvent, listElementRef);
  useEventListener<MouseEvent>('mouseleave', mouseLeaveEvent, listElementRef);

  useEffect(() => {
    const current = listElementRef.current;
    if (!current) {
      return;
    }

    const observer = new MutationObserver((mutations) => {
      const hovered = mutations.find(({ attributeName }) => attributeName === 'data-hover');
      const hover = current.hasAttribute('data-hover');
      if (!hovered) {
        // This means, clicked on item.
        setShow(false);
      } else {
        setShow(hover);
      }
    });

    observer.observe(current, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, [listElementRef]);

  return (
    <li className="group" ref={listElementRef} key={text.toString()}>
      <a href="#" className="flex items-center justify-between">
        {text}
        <Icon type="s:chevron-down" className="menu-chevron" size="sm" />
      </a>
      <Transition
        as={Fragment}
        show={show}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div className="nav-menu-dropdown">
          <ul>
            {menu
              .filter((d) => d.display || d.display === undefined)
              .map((item) => (
                <li key={item.title} className="nav-menu-dropdown-item">
                  <Link
                    onClick={() => onClick?.(item)}
                    to={item.href}
                    target={item.target}
                    className="nav-menu-dropdown-content"
                  >
                    {item.icon && <Icon type={item.icon} size="lg" color="neutral-900" />}

                    <span>
                      <span>{item.title}</span>
                      {item.description && <span>{item.description}</span>}
                    </span>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </Transition>
    </li>
  );
};
