import axios from 'axios';

import addOtherBanksEntry from './addOtherBanksEntry';
import { castBoolean } from './castBoolean';
import { default as concatAssetPair } from './concatAssetPair';
import detectBrowser from './detectBrowser';
import { extractBankCode } from './extractBankCode';
import { escapeRegEx, filterExpression } from './filterExpression';
import { formatIban } from './formatIban';
import { formatIfEmpty } from './formatIfEmpty';
import { getAssetUnitPriceInfo } from './getAssetUnitPriceInfo';
import { getIntegerLength } from './getIntegerLength';
import { default as getIsInternalTransferType, INTERNAL_TRANSFER_TYPES } from './getIsInternalTransferType';
import { getSwapTokens } from './getSwapTokens';
import {
  ERROR_PAGE_STATE,
  extractError,
  getBiLiraErrorCode,
  getErrorMessage,
  default as httpError,
  isHttpError,
} from './httpError';
import { isArrayEmpty } from './isArrayEmpty';
import { isCurrentTimeInRange } from './isCurrentTimeInRange';
import { isEmpty, isObjectValueNull } from './isEmpty';
import { isIbanFromBlacklistedBank } from './isIbanFromBlacklistedBank';
import { isNumber } from './isNumber';
import { isNumberOrStringOrUndefined } from './isNumberOrStringOrUndefined';
import { isNumericValue } from './isNumericValue';
import { isPatternMatching } from './isPatternMatching';
import { isStringOrUndefined } from './isStringOrUndefined';
import { isTimeInRange } from './isTimeInRange';
import { default as getValueByNestedKey } from './mutation/getValueByNestedKey';
import { default as orderByPrice } from './mutation/orderByPrice';
import { sanitizeURL } from './sanitizeURL/sanitizeURL';
import { buildFacebookShareUrl, buildLinkedinShareUrl, buildTwitterShareUrl } from './share';
import { getLocaleDateString } from './timeUtil';
import { truncateMiddle } from './truncateMiddle';
import { validateIban } from './validateIban';

export * from './price';
export * from './currency';
export * from './formatNumberByPrice';
export * from './limits';
export * from './validations';
export * from './assets';
export * from './otc';
export * from './sorting';
export type * from './types';
export * from './reward';
export * from './cleanObject';
export * from './reactQuery';

export const isAxiosError = axios.isAxiosError;

export {
  truncateMiddle,
  validateIban,
  formatIban,
  isEmpty,
  isArrayEmpty,
  isNumber,
  isNumericValue,
  castBoolean,
  getLocaleDateString,
  buildTwitterShareUrl,
  buildLinkedinShareUrl,
  buildFacebookShareUrl,
  httpError,
  extractBankCode,
  orderByPrice,
  getValueByNestedKey,
  isPatternMatching,
  formatIfEmpty,
  isObjectValueNull,
  extractError,
  getErrorMessage,
  getBiLiraErrorCode,
  isHttpError,
  concatAssetPair,
  ERROR_PAGE_STATE,
  isTimeInRange,
  isCurrentTimeInRange,
  getIsInternalTransferType,
  INTERNAL_TRANSFER_TYPES,
  detectBrowser,
  getSwapTokens,
  addOtherBanksEntry,
  getAssetUnitPriceInfo,
  isStringOrUndefined,
  isNumberOrStringOrUndefined,
  filterExpression,
  escapeRegEx,
  getIntegerLength,
  sanitizeURL,
  isIbanFromBlacklistedBank,
};
