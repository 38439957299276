import React, { memo, useMemo } from 'react';

import { cleanObject } from '@bilira-org/react-utils';
import { getCoreRowModel, getSortedRowModel, Table, useReactTable } from '@tanstack/react-table';

import { DataTableColumnProps } from './dataTable';
import { getRowId } from '../helpers';

interface Props<T>
  extends Pick<
    DataTableColumnProps<T>,
    | 'loading'
    | 'skeletonRowCount'
    | 'columns'
    | 'rowId'
    | 'data'
    | 'pinnedColumns'
    | 'getRowCanExpand'
    | 'clientSorting'
    | 'onSortingChange'
    | 'initialSorting'
    | 'sorting'
    | 'disableSorting'
  > {
  children: (table: Table<T>) => React.ReactNode;
}

function DataTableHOC<T>(props: Props<T>) {
  const {
    loading,
    skeletonRowCount,
    columns,
    rowId,
    data: dataFromProps = [],
    pinnedColumns,
    getRowCanExpand,
    children,
    onSortingChange,
    sorting,
    clientSorting,
    initialSorting,
    disableSorting,
  } = props;

  const data = useMemo(() => {
    if (loading) {
      return new Array(skeletonRowCount).fill(null) as T[];
    }
    return dataFromProps;
  }, [dataFromProps, loading, skeletonRowCount]);

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: clientSorting ? getSortedRowModel() : undefined,
    getRowId: getRowId({ rowId, loading }),
    initialState: {
      sorting: initialSorting,
    },
    state: {
      columnPinning: { left: pinnedColumns },
      ...cleanObject({ sorting }),
    },
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
      enableSorting: false,
    },
    getRowCanExpand,
    ...cleanObject({ onSortingChange }),
    manualSorting: !clientSorting,
    enableSorting: !disableSorting,
  });

  return children(table);
}
export default memo(DataTableHOC) as typeof DataTableHOC;
