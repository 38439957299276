import { useEffect, useRef } from 'react';

interface UseScrollEndObserverProps {
  /**
   * Callback function to execute when the observed element intersects with the viewport.
   */
  onIntersect?: () => void;
  /**
   * Threshold at which the callback is triggered. A value of `0` means any part of the element
   * visible will trigger the callback, while `1.0` means the entire element must be visible.
   * Default is `1.0`.
   */
  threshold?: number;
}

/**
 * Custom hook to observe when an element intersects with the viewport.
 * Uses the Intersection Observer API to monitor visibility.
 */
const useIntersectionObserver = <T extends HTMLElement>({
  onIntersect,
  threshold = 1.0,
}: UseScrollEndObserverProps) => {
  const elementRef = useRef<T>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Trigger the callback when the element is fully visible
          if (entry.isIntersecting) {
            onIntersect?.();
          }
        });
      },
      {
        root: null, // Observe relative to the viewport
        rootMargin: '0px',
        threshold,
      },
    );

    const currentElement = elementRef.current;
    if (currentElement && onIntersect) {
      observer.observe(currentElement);
    }

    // Cleanup observer on unmount
    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [elementRef, onIntersect, threshold]);

  return elementRef;
};

export default useIntersectionObserver;
