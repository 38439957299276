import React, { ForwardedRef, forwardRef, PropsWithChildren } from 'react';

import { clsx } from 'clsx';

import { MenuProps } from '../../types';

export const HorizontalMenuGroup = forwardRef<HTMLDivElement, PropsWithChildren<MenuProps>>(
  ({ className, testId, children, id }: PropsWithChildren<MenuProps>, ref: ForwardedRef<HTMLDivElement>) => {
    const cn = clsx('nav-menu', className, {});

    return (
      <div id={id} ref={ref}>
        <ul className={cn} role="menu" data-testid={testId}>
          {children}
        </ul>
      </div>
    );
  },
);
