import React, { PropsWithChildren } from 'react';

import { clsx } from 'clsx';

import { MenuProps } from '../../types';

export const VerticalMenuGroup = ({ className, testId, children, id }: PropsWithChildren<MenuProps>) => {
  const cn = clsx('vertical-nav-menu w-full', className);

  return (
    <ul id={id} className={cn} role="menu" data-testid={testId}>
      {children}
    </ul>
  );
};
