import { QueryClient, QueryKey } from '@tanstack/react-query';

class ReactQueryCache {
  private queryClient: QueryClient;

  /**
   * @param {QueryClient} queryClient
   */
  constructor(queryClient: QueryClient) {
    this.queryClient = queryClient;
  }

  /**
   * Get query data in the client for a specific key.
   *
   * @template T The expected type of the query data.
   * @param {QueryKey} queryKey
   * @returns {T | undefined} The cached query data, or `undefined` if the query does not exist.
   */
  public getQueryData<T>(queryKey: QueryKey) {
    return this.queryClient.getQueryData<T>(queryKey);
  }

  /**
   * Set query data in the client for a specific key.
   * @param {QueryKey} queryKey
   * @returns {Function} returns a function that accepts a callback
   * which modifies the data
   */
  public setQueryData<T>(queryKey: QueryKey) {
    return (callback: (data: T | undefined) => T | undefined) => {
      this.queryClient.setQueryData(queryKey, (oldData: T | undefined) => oldData && callback(oldData as T));
    };
  }

  /**
   * Set queries data in the client for a specific key.
   * @param {QueryKey} queryKey
   * @returns {Function} returns a function that accepts a callback
   * which modifies the data
   */
  public setQueriesData<T>(queryKey: QueryKey) {
    return (callback: (data: T) => T | undefined) => {
      this.queryClient.setQueriesData({ queryKey: queryKey, exact: false }, (oldData) =>
        oldData ? callback(oldData as T) : undefined,
      );
    };
  }

  /**
   * Set all queries data in the client for a specific key.
   * @param {QueryKey} queryKey
   * @returns {Function} returns a function that accepts a callback
   * which modifies the data
   */
  public setAllQueriesData<T>(queryKey: QueryKey) {
    const queries = this.queryClient.getQueriesData({
      queryKey: queryKey,
      exact: false,
    });
    return (callback: (data: T | undefined) => T | undefined) => {
      queries.forEach((query: [QueryKey, unknown]) => {
        const [key] = query;

        const priceQuery = this.setQueryData<T>(key);
        priceQuery(callback);
      });
    };
  }

  /**
   * Invalidates a specific query in the client.
   * @param {Array} queryKey
   */
  public invalidateQuery(queryKey: unknown[]) {
    this.queryClient.invalidateQueries({ queryKey }).then();
  }

  /**
   * Refetches a specific query in the client.
   * @param {Array} queryKey
   */
  public refetchQuery(queryKey: unknown[]) {
    this.queryClient.refetchQueries({ queryKey }).then();
  }

  /**
   * Resets a specific query in the client.
   * @param {Array} queryKey
   */
  public resetQueries(queryKey: unknown[]) {
    this.queryClient.resetQueries({ queryKey }).then();
  }
}

export default ReactQueryCache;
