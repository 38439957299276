import React, { FC, forwardRef, HTMLAttributes, PropsWithChildren } from 'react';

import { clsx } from 'clsx';

import Announcement from './announcement';
import useIosViewPort from '../../hooks/useIosViewport';
import { BackgroundColorType, BaseComponentType } from '../types';

export type LayoutProps = HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** Additional class name for the Layout component */
    className?: string;
    /** The content to be displayed within the Layout component */
    children: React.ReactNode;
    /** Background color of the Layout component */
    color?: BackgroundColorType;
    /** Variant */
    variant?: 'stablecoin' | 'direct';
  };
export type HeadProps = HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** Additional class name for the Head component */
    className?: string;
    /** The content to be displayed within the Head component */
    children: React.ReactNode;
    /** Variant */
    variant?: 'stablecoin' | 'direct';
  };

export type MainProps = HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** Additional class name for the Main component */
    className?: string;
    /** The content to be displayed within the Main component */
    children: React.ReactNode;
  };

export type ContainerProps = HTMLAttributes<HTMLDivElement> &
  BaseComponentType &
  PropsWithChildren & {
    /** Additional class name for the Container component */
    className?: string;
    withSidebar?: boolean;
  };

export type ContentProps = HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** Additional class name for the Content component */
    className?: string;
    /** The content to be displayed within the Content component */
    children: React.ReactNode;
    /** Indicates whether overflow should be hidden for the Content component */
    overflowHidden?: boolean;
  };

export type HeroProps = HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** The content to be displayed within the Hero component */
    children: React.ReactNode;
    /** Indicates whether horizontal padding should be removed until small screens */
    pxNoneUntilSm?: boolean;
  };

export type AsideProps = HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** The content to be displayed within the Aside component */
    children: React.ReactNode;
    /** Indicates whether the Aside component is responsive */
    responsive?: boolean;
    /** Additional class name for the Aside component */
    className?: string;
    /** The ID for the Aside component */
    id?: string;
  };

const Layout: FC<LayoutProps> = ({ children, color, className, testId, variant }: LayoutProps) => {
  useIosViewPort();
  const classes = clsx(`layoutV2 group layout-${variant}`, className, {
    [`tw-bg-${color}`]: color,
  });

  return (
    <div className={classes} data-testid={testId ?? 'layout'}>
      {children}
    </div>
  );
};

const Head = forwardRef<HTMLElement, HeadProps>(({ children, className, testId }, ref) => {
  const classes = clsx(`layout-head`, className);

  return (
    <header ref={ref} className={classes} data-testid={testId}>
      {children}
    </header>
  );
});

const Header = forwardRef<HTMLElement, HeadProps>(({ children, className, testId }, ref) => {
  const classes = clsx(`layout-header`, className);

  return (
    <section ref={ref} className={classes} data-testid={testId}>
      {children}
    </section>
  );
});

const Main = React.forwardRef<HTMLDivElement, MainProps>(({ children, className, testId, ...props }, ref) => {
  const classes = clsx('layout-main', className);
  return (
    <div role="main" className={classes} ref={ref} data-testid={testId ?? 'layout-main'} {...props}>
      {children}
    </div>
  );
});

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, className, testId, withSidebar }, ref) => {
    const classes = clsx('layout-container', { 'layout-container-with-sidebar': withSidebar }, className);
    return (
      <div className={classes} ref={ref} data-testid={testId}>
        {children}
      </div>
    );
  },
);

const InnerContent: React.FC<ContentProps> = ({
  children,
  overflowHidden,
  className,
  testId,
  ...props
}: ContentProps) => {
  const classes = clsx('layout-inner-content', className);

  return (
    <div className={classes} data-testid={testId} {...props}>
      {children}
    </div>
  );
};

const Content: React.FC<ContentProps> = ({ children, overflowHidden, className, testId, ...props }: ContentProps) => {
  const classes = clsx('layout-content', className);

  return (
    <div className={classes} data-testid={testId} {...props}>
      {children}
    </div>
  );
};

const Hero: React.FC<HeroProps> = ({ children, className, pxNoneUntilSm, testId, ...props }: HeroProps) => {
  const classes = clsx('layout-hero', className);

  return (
    <div className={classes} data-testid={testId} {...props}>
      {children}
    </div>
  );
};

const Aside: React.FC<AsideProps> = ({ children, responsive, className, id, testId }: AsideProps) => {
  const classes = clsx('layout-aside', className);
  return (
    <div className={classes} id={id} data-testid={testId}>
      {children}
    </div>
  );
};

Layout.displayName = 'Layout';

export default Object.assign(Layout, {
  Aside,
  Header,
  InnerContent,
  Head,
  Content,
  Main,
  Hero,
  Container,
  Announcement,
});
